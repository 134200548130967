// Navigation toggle
window.addEventListener('load', function () {
      let main_navigation = document.querySelector('#primary-menu');
      // document.querySelector('#primary-menu-toggle').addEventListener('click', function (e) {
      //       e.preventDefault();
      //       main_navigation.classList.toggle('hidden');
      // });

			jQuery('.woocommerce div.product form.cart .variations select').select2();
			jQuery('.orderby').select2();
			jQuery('.alternating-product-selector-item select').select2();
			jQuery('.product-select-block select').select2();
			jQuery('.wc-default-select').select2();
});

jQuery(document).ready(function($) {
	$(window).scroll( function(){
			$('.fadein').each( function(i){
					
					var bottom_of_element = $(this).offset().top;
					var bottom_of_window = $(window).scrollTop() + $(window).height();
					
					if( bottom_of_window > bottom_of_element ){
							$(this).addClass('faded');
					}
					
			}); 
	});

	$('#primary-menu-toggle').click(function(e) {
		e.preventDefault();
		$('.mobile-menu-overlay').toggleClass('overlay-active');
		$('.mobile-menu').toggleClass('menu-open');
        $('html').toggleClass('menu-open');
	});
	$('.close-button, .mobile-menu-overlay, .mobile-menu-bg-overlay').click(function(e) {
		e.preventDefault();
		$('.mobile-menu-overlay').removeClass('overlay-active');
		$('.mobile-menu').removeClass('menu-open');
        $('html').removeClass('menu-open');
	});

	
});

jQuery(function ($) {
	// Listen for the "Add to Cart" button click.
	$('body').on('click', '.single_add_to_cart_button', function (e) {
		console.log('clicked');
			
		if ($(this).hasClass("wc-variation-selection-needed")) {
				// e.preventDefault()
				// Create a new <div> element
				var newDiv = $("<div class='variation-select-warning'>Please select a valid product.</div>");

				// Find the table with the class "variations" and add the new <div> element before it
				$("table.variations").before(newDiv);
		}
	});
	$('.variations_form').on('found_variation', function(event, variation) {
		console.log('found');
		// Check if a specific variation ID is selected.
		$(".variation-select-warning").remove();
	});

	jQuery(document).ready(function($) {
		$(".flex-control-thumbs").not('.slick-initialized').slick({
			dots: false,
			arrows: true,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			cssEase: 'linear',
			responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
    	]
		});
	});

});
